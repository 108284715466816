<script setup>
import { computed } from "vue";
import { getFormattedMoney } from "@/utils/functions.js";
import Table from "../utils/Table.vue";
import ActionButton from "../utils/ActionButton.vue";
import { actions } from "@/utils/constants";

const props = defineProps({
  billItems: {
    type: Array,
    default: null,
  },
  total: {
    type: [String, Number],
    default: null,
  },
  billItemsRemovable: {
    type: Boolean,
    default: false,
  },
});

const billItemsNotEmpty = computed(() => {
  return props.billItems !== null && props.billItems.length > 0;
});

function getItemName(bill) {
  const billName = bill.bill_name;
  const billNameNotEmpty = billName !== null && billName !== undefined;
  if (billNameNotEmpty) {
    return billName;
  }
  return bill.fee_name;
}
</script>

<template>
  <Table
    :headers="[
      {
        text: 'Item Name',
        value: 'itemName',
        fn: (billItem) => getItemName(billItem),
      },
      {
        text: 'Quantity',
        value: 'quantity',
      },
      {
        text: 'Unit of Measure',
        value: 'uom',
      },
      {
        text: 'Subtotal',
        value: 'sub_total',
        fn: (billItem) => getFormattedMoney(billItem.sub_total),
      },
      ...(props.billItemsRemovable
        ? [
            {
              text: '',
              value: 'billItemRemovable',
              action: true,
            },
          ]
        : []),
    ]"
    :items="billItems"
  >
    <template #header.billItemRemovable>
      <span>Remove</span>
    </template>
    <template #item.billItemRemovable="{ item: billItem }">
        <ActionButton
          :action="actions.removeItem"
          inTable
          @click="$emit('removeBillItem', billItems.indexOf(billItem))"
        >
        </ActionButton>
    </template>
  </Table>
</template>
