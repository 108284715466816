<script setup>
import PropertySearchTable from './PropertySearchTable.vue';
import PropertySearchBillDialog from './PropertySearchBillDialog.vue';
import ViewSearchCert from './ViewSearchCert.vue';
import {
    useFetchAllProperties, useFetchPropertySearchFees, useGeneratePropertySearch,
    useGetValidPropertySearch
} from '../../properties_business_logic';
import { showSnackbarForResponseError } from '@/utils/vue_helpers';
import { setSnackbar } from '@/components/utils/snackbar/logic';
import { errors } from '@/utils/constants';
import { computed, ref } from 'vue';
import ViewBill from '@/components/billing/view_bills/ViewBill.vue';

const pages = {
    PROPERTY_SEARCH: 0,
    VIEW_BILL: 1
}

const currentPage = ref(pages.PROPERTY_SEARCH)

const billToView = ref(null)

const billToViewNotEmpty = computed(() => {
    return !!billToView.value
})

const goTo = {
    propertySearch() {
        currentPage.value = pages.PROPERTY_SEARCH
    },
    viewBill(bill) {
        billToView.value = bill
        currentPage.value = pages.VIEW_BILL
    }
}

const {
    propertiesData,
    searchModel
} = useFetchAllProperties()

const propertySearchFees = useFetchPropertySearchFees()

const pageLoading = computed(() => {
    return propertiesData.fetchingData || propertySearchFees.isLoading.value
})

const canOpenDialog = computed(() => {
    return !pageLoading.value && !propertySearchFees.isError.value && !!propertyToSearch.value
})

const billDialogOpen = ref(false)

const propertyToSearch = ref(null)

function onSearchPropertyClicked(property) {
    if (!propertySearchFees.isError.value) {
        propertyToSearch.value = property
        billDialogOpen.value = true
    } else {
        showSnackbarForResponseError(propertySearchFees.error)
    }
}

function onPaginationClicked(newPage) {
    propertiesData.setNewPage(newPage)
}

const generatePropertySearch = useGeneratePropertySearch()
function onGeneratePropertySearchBillClicked(property) {
    generatePropertySearch.mutate({
        prn: property.prn
    }, {
        onSuccess(data) {
            billDialogOpen.value = false
            setSnackbar({
                text: 'Property Search Bill Generated',
                color: 'success',
                open: true
            })
        },
        onError(error) {
            if (error === errors.PROPERTY_NOT_FOUND) {
                setSnackbar({
                    text: 'Property search could not be generated',
                    color: 'error',
                    open: true
                })
            }
        }
    }
    )
}

const validPropertySearch = useGetValidPropertySearch()
</script>

<template>
    <v-container class="pt-8">
        <v-tabs-items v-model="currentPage">
            <v-tab-item :value="pages.PROPERTY_SEARCH">
                <v-row>
                    <v-col cols="12" class="mt-4">
                        <v-card>
                            <v-row>
                                <v-col cols="12">
                                    <div class="title text-center primary--text">Property Search</div>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <ViewSearchCert @viewBillClicked="goTo.viewBill" />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <PropertySearchTable :properties="propertiesData.fetchedData"
                                        :propertiesNotEmpty="propertiesData.fetchedDataNotEmpty" :loading="pageLoading"
                                        :paginationData="propertiesData.paginationData" :searchValue.sync="searchModel"
                                        @paginationClicked="onPaginationClicked"
                                        @searchPropertyClicked="onSearchPropertyClicked" />
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>

                <PropertySearchBillDialog v-if="canOpenDialog" :dialogOpen.sync="billDialogOpen"
                    :searchFees="propertySearchFees.data.value" :property="propertyToSearch"
                    :generatingBill="generatePropertySearch.isLoading.value"
                    @generatePropertySearchBill="onGeneratePropertySearchBillClicked" />
            </v-tab-item>

            <v-tab-item :value="pages.VIEW_BILL">
                <v-row>
                    <v-col cols="12" class="py-8 px-8">
                        <ViewBill v-if="billToViewNotEmpty" :bill="billToView" showGoBack
                            @goBackClicked="goTo.propertySearch">
                            <template #title>
                                <div class="title text-center primary--text">
                                    Bill Details
                                </div>
                            </template>
                        </ViewBill>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>