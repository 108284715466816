<script setup>
import { getFormattedMoney } from '@/utils/functions'
import { computed } from 'vue'

const props = defineProps({
    dialogOpen: {
        type: Boolean,
        default: false
    },
    searchFees: {
        type: Array,
        default: null
    },
    property: {
        type: Object,
        default: null
    },
    generatingBill: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['update:dialogOpen'])


const innerDialogOpen = computed({
    get() {
        return props.dialogOpen
    },
    set(newVal) {
        emit('update:dialogOpen', newVal)
    }
})

const total = computed(() => {
    return props.searchFees.reduce((total, fee) => {
        return Number(fee.amount) + total
    }, 0)
})
</script>

<template>
    <v-dialog v-model="innerDialogOpen" max-width="600" :persistent="generatingBill">
        <v-card>
            <v-toolbar color="primary" flat>
                <v-toolbar-title class="white--text">
                    Generate Property Search Bill
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <v-btn icon color="primary" v-bind="attrs" v-on="on" @click="innerDialogOpen = false"><v-icon
                                    class="white--text">mdi-close</v-icon></v-btn>
                        </template>
                        <span>Close</span>
                    </v-tooltip>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text class="px-1 pb-2">
                <v-form @submit.prevent="$emit('generatePropertySearchBill', property)">
                    <v-container>
                        <v-row>
                            <v-col cols="auto">
                                <div class="pl-4">
                                    Property PRN: {{ property.prn }}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-simple-table>
                                    <template #default>
                                        <thead>
                                            <tr>
                                                <th>Item Name</th>
                                                <th class="text-right">Amount</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr v-for="(fee, index) in searchFees" :key="index">
                                                <td>
                                                    {{ fee.fee_name }}
                                                </td>
                                                <td class="text-right">
                                                    {{ getFormattedMoney(fee.amount) }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="font-weight-bold">Total</td>
                                                <td class="font-weight-bold text-right">{{ getFormattedMoney(total) }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>

                        <v-row justify="end">
                            <v-col cols="auto">
                                <v-btn color="secondary" class="text-none" depressed type="submit"
                                    :loading="generatingBill">
                                    Submit <v-icon right>mdi-content-save</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>