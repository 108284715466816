<script setup>
import ViewPdf from '@/components/utils/ViewPdf.vue';
import { useDownloadFile } from '@/utils/vue_helpers';
import { ref } from 'vue';

const props = defineProps({
    prn: {
        type: String,
        default: null
    }
})

const pdfOpen = ref(false)
const pdfUrl = `${process.env.VUE_APP_API_URL}/api/client/property/generate-property-search-pdf?prn=${props.prn}`

const { downloadFile } = useDownloadFile()

function onPrintSearchCertClicked() {
    downloadFile({ propertySearchCertPrn: props.prn })
    // pdfOpen.value = true
}
</script>

<template>
    <div>
        <div class="d-flex align-center">
            <v-btn depressed class="secondary text-none" @click="onPrintSearchCertClicked">
                Download Search Certificate
                <v-icon right>mdi-download-circle</v-icon>
            </v-btn>
            <!-- 
            <v-tooltip v-if="!idleOrLoading && !propertyHasSearchCert" top>
                <template #activator="{ on, attrs }">
                    <v-icon class="error--text ml-2" v-on="on" v-bind="attrs">
                        mdi-alert-circle
                    </v-icon>
                </template>
                <span>
                    This property has no Search Certificate
                </span>
            </v-tooltip> -->
        </div>

        <ViewPdf v-if="!!prn" title="Property Search Certificate" :url="pdfUrl" :open.sync="pdfOpen" />
    </div>
</template>