<script setup>
import LabelledField from '@/components/utils/LabelledField.vue'
import Pagination from '@/components/utils/Pagination.vue';
import LoadingArea from '@/components/utils/LoadingArea.vue';
import { computed, ref, watch } from 'vue';

const props = defineProps({
    properties: {
        type: Array,
        default: null
    },
    propertiesNotEmpty: {
        type: Boolean,
        default: false
    },
    loading: {
        type: Boolean,
        default: false
    },
    searchValue: {
        type: String,
        default: null
    },
    paginationData: {
        type: Object,
        default: null
    }
})

const emit = defineEmits(['update:searchValue'])

const searchModel = computed({
    get() {
        return props.searchValue
    },
    set(newVal) {
        emit('update:searchValue', newVal)
    }
})

const searchModelNotEmpty = computed(() => {
    const searchModelNotNullOrUndefined = !!searchModel.value
    const searchModelNotSpaceOnly = !!searchModel.value?.trim()
    return searchModelNotNullOrUndefined && searchModelNotSpaceOnly
})

const emptyText = ref('')

watch(computed(() => props.loading), (newVal) => {
    const notLoading = newVal === false
    const propertiesEmpty = !props.propertiesNotEmpty
    console.log(notLoading)
    if (propertiesEmpty && notLoading) {
        if (searchModelNotEmpty.value) {
            emptyText.value = 'No items matched your search'
        } else {
            emptyText.value = 'Please enter a search value'
        }
    }

})
</script>

<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="6">
                <LabelledField label="Search">
                    <v-text-field class="background text-field" outlined dense v-model="searchModel" clearable />
                </LabelledField>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <LoadingArea :loading="loading">
                    <v-row>
                        <v-col cols="12">
                            <v-simple-table>
                                <template #default>
                                    <thead>
                                        <tr class="primary">
                                            <th class="text-left white--text">PRN No</th>
                                            <th class="text-left white--text">LR Number</th>
                                            <th class="text-left white--text">Block / Plot Number</th>
                                            <th class="text-right white--text">Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <template v-if="propertiesNotEmpty">
                                            <tr v-for="property in properties">
                                                <td>
                                                    {{ property.prn }}
                                                </td>
                                                <td>
                                                    {{ property.lr_no }}
                                                </td>
                                                <td>
                                                    {{ property.block_no }}/{{ property.plot_no }}
                                                </td>
                                                <td class="text-right">
                                                    <v-tooltip top>
                                                        <template #activator="{ on, attrs }">
                                                            <v-btn icon color="primary" v-bind="attrs" v-on="on"
                                                                @click="$emit('searchPropertyClicked', property)"><v-icon>mdi-magnify</v-icon></v-btn>
                                                        </template>
                                                        <span>Search</span>
                                                    </v-tooltip>
                                                </td>
                                            </tr>
                                        </template>

                                        <tr v-else>
                                            <td colspan="7" class="text-center">
                                                {{ loading ? 'Loading' : emptyText }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>

                    <v-row justify="end">
                        <v-col cols="auto">
                            <Pagination :totalNumberOfItems="paginationData.total" :itemsPerPage="paginationData.perPage"
                                :currentPage="paginationData.currentPage"
                                @paginationClicked="$emit('paginationClicked', $event)" />
                        </v-col>
                    </v-row>
                </LoadingArea>
            </v-col>
        </v-row>
    </v-container>
</template>