<script setup>
import PrintPropertySearchButton from './PrintPropertySearchButton.vue';
import { useGetValidPropertySearch } from '../../properties_business_logic';
import { routes } from '@/router/routes';
import { useDownloadFile, useRouter } from '@/utils/vue_helpers';
import { computed } from 'vue';

const emit = defineEmits(['viewBillClicked'])

const validPropertySearch = useGetValidPropertySearch()

const statuses = {
    NO_PROPERTY_SEARCH_FOUND: 1,
    BILL_NOT_PAID: 2,
    PROPERTY_SEARCH_VALID: 3
}

const loading = computed(() => {
    return validPropertySearch.isLoading.value || validPropertySearch.isFetching.value
})

const propertySearch = computed(() => {
    let propertySearch = null
    propertySearch = validPropertySearch.data.value?.property_search ?? null
    return propertySearch
})

const status = computed(() => {
    const data = validPropertySearch.data.value ?? null
    const propertySearchNotFound = data?.has_property_search === false
    if (propertySearchNotFound) {
        return statuses.NO_PROPERTY_SEARCH_FOUND
    }
    const billNotPaid = data?.bill_paid === false
    if (billNotPaid) {
        return statuses.BILL_NOT_PAID
    }
    return statuses.PROPERTY_SEARCH_VALID
})

const { router } = useRouter()
function onPayBillClicked() {
    const billNumber = propertySearch.value?.bill_number
    const billNumberNotEmpty = !!billNumber
    if (billNumberNotEmpty) {
        router.value.push(routes.payment({
            billNumbersToPayAsString: billNumber
        }))
    }
}

const { downloadFile } = useDownloadFile()

function onViewBillClicked() {
    if (status.value === statuses.BILL_NOT_PAID) {
        const bill = validPropertySearch.data.value?.bill
        const billNotEmpty = !!bill
        if (billNotEmpty) {
            downloadFile({ billId: bill.id, billNumber: bill.bill_number })
            // emit('viewBillClicked', bill)
        }
    }
}
</script>

<template>
    <v-container fluid class="py-0">
        <template v-if="loading">
            <v-row justify="center">
                <v-col cols="7">
                    <v-card flat outlined>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-skeleton-loader type="text" />
                                    <v-skeleton-loader type="text" />
                                </v-col>
                            </v-row>

                            <v-row justify="center">
                                <v-col cols="auto">
                                    <v-skeleton-loader type="button" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <template v-else>
            <v-row v-if="status === statuses.PROPERTY_SEARCH_VALID">
                <v-col cols="auto">
                    <PrintPropertySearchButton :prn="propertySearch?.prn" />
                </v-col>
            </v-row>

            <v-row v-else justify="center">
                <v-col cols="12" md="7">
                    <v-card flat outlined>
                        <v-card-text>
                            <template v-if="status === statuses.NO_PROPERTY_SEARCH_FOUND">
                                <v-row>
                                    <v-col cols="12">
                                        <div class="text-center">
                                            No previous property search certificates found. Please click on the search icon
                                            in the table
                                            below to generate a search certifiate for the desired property
                                        </div>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-if="status === statuses.BILL_NOT_PAID">
                                <v-row>
                                    <v-col cols="12">
                                        <div class="text-center">
                                            You have a pending property search bill for the property
                                            PRN:{{ propertySearch.prn }}.
                                            Please pay the bill to access your property search certificate.
                                            <!-- Block / Plot Number: {{ propertySearch.block_no }}/{{ propertySearch.plot_no }} -->
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row justify="center">
                                    <v-col cols="auto">
                                        <v-btn depressed class="secondary text-none" @click="onViewBillClicked">
                                            Download Bill
                                            <v-icon right> mdi-download-circle</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn depressed class="success text-none" @click="onPayBillClicked">
                                            Pay Bill
                                            <v-icon right> mdi-cash</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>

    </v-container>
</template>